import React from "react"
import { Link,graphql} from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSmall from "../components/headerSmall"
import ComparisonTable from "../components/comparisonTable"
import InsuranceDetailsSection from "../components/insuranceDetailsSection"

export const query = graphql`
    query ($provider: String,$product: String){
      site {
        siteMetadata {
        title,
        year,
        guideyear
        }
      },
      barneforsikring2020Csv(Provider: {eq: $provider}, Product: {eq: $product}){
        Provider
        Product
        Defaqto_Stjerne
      },
      table: allBarneforsikring2020Csv(sort: {fields: Defaqto_Stjerne, order: DESC}) {
        edges {
          node{
            Provider
            Product
            Defaqto_Stjerne
          }
        }
      }
      barn5: file(relativePath: { eq: "2020/barn5.png" }) {
          ...tblStarImage
      },
      barn4: file(relativePath: { eq: "2020/barn4.png" }) {
          ...tblStarImage
      },
      barn3: file(relativePath: { eq: "2020/barn3.png" }) {
          ...tblStarImage
      },
      barn2: file(relativePath: { eq: "2020/barn2.png" }) {
          ...tblStarImage
      },
      barn1: file(relativePath: { eq: "2020/barn1.png" }) {
          ...tblStarImage
      }
    }
  `

export default({data}) => {
  const year = data.site.siteMetadata.year
  const type = "barneforsikring"
  const path = "barn"
  const aspects = "35"

  const provider = data.barneforsikring2020Csv.Provider
  const providerUrl = "" // Not used atm - but should be implemented
  const product = data.barneforsikring2020Csv.Product
  const rating = data.barneforsikring2020Csv.Defaqto_Stjerne

  return(
    <Layout>
      <InsuranceDetailsSection
        table={data.table}
        year={year}
        type={type}
        path={path}
        aspects={aspects}
        provider={provider}
        providerUrl={providerUrl}
        rating={rating}
        product={product}
      />
    </Layout>
  )
}